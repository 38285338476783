/* React Kanban */
body .react-kanban-column { 
	background-color: #fff; /* Gray/Gray-100 */
	box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
	border:1px solid;
	border-color: #DADEEC;
	border-radius: 14px;
}
body.dark .react-kanban-column {
	background-color: #111c44; /* Gray/Gray-100 */
	border-radius: 14px;
	border-color: #ffffff33;
	box-shadow: none;
}
.react-kanban-column {
	overflow: hidden;
	width: 500px;
}
.react-kanban-card-adder-form {
	width: 100%;
	border-radius: 6px;
}
.react-kanban-card-adder-button {
	display: none;
	border-radius: 6px;
}
/* Full Calendar */
a.fc-daygrid-day-number {
	width: 100%;
	text-align: center;
}
a.fc-daygrid-day-number {
	width: 100%;
	text-align: center;
}
a.fc-daygrid-day-number {
	width: 100%;
	text-align: center;
}
div.fc-daygrid-event-harness {
	top: unset;
}
.fc-daygrid-event-harness .fc-daygrid-event-harness-abs {
	top: 0px;
	bottom: unset;
}
.fc-daygrid-block-event .fc-event-title {
	font-weight: 500;
}
.fc-daygrid-event {
	border-radius: 10px !important;
	height: 44px;
	display: flex !important;
	overflow: hidden;
	justify-content: center;
	align-items: center;
}
.fc-daygrid-event .fc-event-main {
	margin-top: auto;
	margin-bottom: auto;
}

body.dark .fc-theme-standard td {
	border-color: #ffffff1a !important;
}

.fc .fc-scrollgrid-section-header > *,
.fc .fc-scrollgrid-section-footer > * {
	border-right: 0px !important;
}
.fc-theme-standard .fc-scrollgrid {
	border: 1px solid #e0e5f2 !important;
	border-radius: 10px;
}
body.dark .fc-theme-standard .fc-scrollgrid {
	border-color: #ffffff1a !important;
}

body .fc-col-header-cell.fc-day.fc-day-mon {
	border-right: 0px;
	border-left: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-mon {
	border-right: 0px;
	border-left: 0px;
	border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-tue {
	border-left: 0px;
	border-right: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-tue {
	border-left: 0px;
	border-right: 0px;
	border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-wed {
	border-right: 0px;
	border-left: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-wed {
	border-right: 0px;
	border-left: 0px;
	border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-thu {
	border-left: 0px;
	border-right: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-thu {
	border-left: 0px;
	border-right: 0px;
	border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-fri {
	border-right: 0px;
	border-left: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-fri {
	border-right: 0px;
	border-left: 0px;
	border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-sat {
	border-left: 0px;
	border-right: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-sat {
	border-left: 0px;
	border-right: 0px;
	border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-sun {
	border-left: 0px;
	border-right: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-sun {
	border-left: 0px;
	border-right: 0px;
	border-color: #ffffff1a;
}

body .fc-scrollgrid.fc-scrollgrid-liquid > td {
	border: 0px;
}
body.dark .fc-scrollgrid.fc-scrollgrid-liquid > td {
	border: 0px;
	border-color: #ffffff1a;
}

.fc .fc-scrollgrid-section > td {
	border: 0;
}
